<template>
  <div>
    <v-tabs
      v-model="activeTab"
      background-color="transparent"
      color="basil"
      class="d-flex"
    >
      <!--:key - формируется из данных, при изменении которых необходимо обновлять вкладки-->
      <v-tab
        v-for="(item, index) in tabs"
        :key="categoryName + index + item.key + tabs.length"
        class="tabsBlock"
        :class="tabsColor(item)"
        style="max-width: 120px"
      >
        <div
          class="d-flex flex-column mr-1"
          :key="item.data.client_id"
        >
          <v-tooltip bottom>
            <template v-slot:activator="{ on }">
              <div
                class="caption d-block size"
                v-on="on"
              >{{ tabTitle(item) | truncate(10, '...') }}
              </div>
            </template>
            {{ tabTitle(item) }}
          </v-tooltip>
          <v-tooltip bottom>
            <template v-slot:activator="{ on }">
              <v-btn
                v-on="on"
                v-if="item.data.client_id"
                :disabled="activeTab !== index"
                class="tabButton size"
                text
                x-small
                @click.stop="handlePushLink(item)"
              >
                {{ formatValue('users.clients', item.data.client_id) | truncate(8, '...') }}
              </v-btn>
            </template>
            {{
              formatValue('users.clients', item.data.client_id) ? formatValue('users.clients', item.data.client_id) :
                'Клиент без имени'
            }}
          </v-tooltip>
        </div>
        <div
          class="d-flex flex-column justify-start ml-1"
          style="min-height: 39px"
        >
          <v-btn
            class="tabButton"
            icon
            x-small
            @click="remove(categoryName, index)"
          >
            <v-icon small>mdi-close</v-icon>
          </v-btn>
          <TabsDetail
            v-if="(item.type === 'list' || item.type === 'file') && categoryName === 'advert'"
            :data="{title: item.data.title, agent_id: item.data.agent_id, client_id: item.data.client_id}"
            @change="update(categoryName, activeTab, $event)"
          />
        </div>
      </v-tab>
      <a
        @click="add(categoryName, 'list', null)"
        class="addTab ml-1"
      >
        <v-icon
          size="large"
          class="pl-4 pa-4 "
        >
          mdi-plus
        </v-icon>
      </a>
    </v-tabs>
    <v-tabs-items
      v-model="activeTab"
      :key="categoryName + tabs.length"
    >
      <v-tab-item
        v-for="(item, index) in tabs"
        :key="categoryName + index + item.key + tabs.length"
      >
        <router-view
          :name="item.type"
          :value="item.data"
          @change="update(categoryName, activeTab, $event)"
          @detail="handleDetail(categoryName, 'detail', $event)"
          @detail-delete="remove(categoryName, index)"
          @list="add(categoryName, 'list', $event)"
          @file="add(categoryName, 'file', $event)"
        />
      </v-tab-item>
    </v-tabs-items>
  </div>
</template>

<script>
/*
1. При открытии новой категории меню добавляется вкладка с выборкой по умолчанию
2. При открытии нового таба добавляется вкладка с выборкой по умолчанию
3. При закрытии табов должен остаться минимум 1.
*/
import tabActions from '@/components/mixins/tabActions'
import * as Config from '@/config'
import TabsDetail from '@/components/views/admin/common/TabsDetail'
import formatters from '@/components/mixins/formatters'

export default {
  components: { TabsDetail },
  mixins: [tabActions, formatters],
  name: 'TabsContainer',
  data: function () {
    return {}
  },
  filters: {
    truncate: function (text, length, suffix) {
      if (text && text.length > length) {
        return text.substring(0, length) + suffix
      } else {
        return text
      }
    }
  },
  computed: {
    tabs () {
      let tabs = this.$store.getters['tab/byCategory'](this.categoryName)
      if (tabs.length === 0) {
        this.add(this.categoryName, 'list', null)
        tabs = this.$store.getters['tab/byCategory'](this.categoryName)
      }
      return tabs
    }
  },
  methods: {
    tabTitle (item) {
      let title = ''
      if (item.data.title) {
        title = item.data.title
      } else {
        if (item.type === 'list') {
          title = 'Выборка'
        } else if (item.type === 'detail') {
          if (item.data.requestParams.id) {
            title = 'ID ' + item.data.requestParams.id
          } else {
            title = 'Создать'
          }
        } else if (item.type === 'file') {
          title = 'Выгрузка'
        }
      }
      return title
    },
    tabsColor (item) {
      let resultClass = ''
      switch (item.type) {
        case 'detail':
          if (item.data.model && item.data.model.source === Config.advertSources.public) {
            resultClass = 'adv-public '
          } else {
            resultClass = 'advert-tabs'
          }
          break
        case 'list':
          resultClass = 'blue lighten-5'
      }
      return resultClass
    },
    tabsClientName (item) {
      if (item.data.client_id) {
        return this.formatValue('users.clients', item.data.client_id)
      }
    },
    handleDetail (categoryName, type, data) {
      if (data) {
        data = { requestParams: { id: data.id } }
      }
      if (this.$route.name !== categoryName) {
        this.$router.push({ name: categoryName })
      } else {
        this.add(categoryName, type, data)
      }
    },
    handlePushLink (data) {
      let tabIndex = this.$store.getters['tab/indexByClientId']('clients', data.data.client_id)
      if (tabIndex !== null && tabIndex > -1) {
        this.$store.commit('tab/setActiveTab', { categoryName: 'clients', tabIndex })
      } else {
        this.add('clients', 'detail', {
          requestParams: {
            id: data.data.client_id,
            options: {}
          }
        })
      }
      this.$router.push({ name: 'clients' })
    }
  }
}
</script>

<style scoped>
  .tabsBlock {
    border: 1px solid #e3e3e3;
    border-radius: 6px 6px 0px 0px;
    border-bottom: 1px solid transparent;
    padding-left: 5px;
    padding-right: 5px;
    background: #fff;
  }
  .tabButton {
    color: #000;
    border-radius: 3px;
  }
  .tabButton:hover {
    background: #ccc !important;
  }
  .addTab {
    background: #fff;
  }
  .addTab:hover {
    background: #ccc !important;
  }
  .size {
    white-space: nowrap; /* Отменяем перенос текста */
    overflow: hidden; /* Обрезаем содержимое */
    padding: 5px; /* Поля */
    text-overflow: ellipsis !important; /* Многоточие */
  }
  .advert-tabs {
    background: lightgoldenrodyellow;
  }
</style>

<style>
  .statusGreen {
    background-color: rgba(12, 252, 83, 0.54) !important;
  }
  .statusYellow {
    background-color: rgba(252, 245, 7, 0.54) !important;
  }
  .statusGold {
    background-color: rgba(252, 175, 0, 0.54) !important;
  }
  .statusBlue {
    background-color: rgb(53, 89, 232) !important;
  }
  .statusScrappedBefore {
    background: orange;
  }
  .statusRed {
    background-color: #ff1e1e !important;
  }
  .adv-deleted {
    background-color: #ff1e1e !important;
  }
  .adv-public {
    background-color: #b1ffb9 !important;
  }
  .adv-sold {
    background-color: #ffbf92 !important;
  }
  .not-verified {
    background-color: #f07b7b;
  }
  .delay-until {
    background-color: rgba(252, 245, 7, 0.54) !important;
  }
  .selected-item {
    opacity: 0.5 !important;
  }
</style>
