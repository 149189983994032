<template>
  <v-menu
    v-model="isOpen"
    max-width="200px"
    :close-on-content-click="false"
    :close-on-click="true"
    transition="slide-x-transition"
    bottom
    right
    offset-x
  >
    <template v-slot:activator="{ on }">
      <div class="d-flex justify-space-between pt-0">
        <v-btn
          v-on="on"
          class="tabButton"
          x-small
          icon
        >
          <v-icon small>mdi-pencil</v-icon>
        </v-btn>
      </div>
    </template>
    <v-card>
      <v-card-text>
        <v-row>
          <v-col
            cols="12"
            class="pa-0"
          >
            <v-textarea
              outlined
              v-model="localData.title"
              label="Название вкладки"
              class="pa-2"
              hide-details
              rows="1"
            ></v-textarea>
          </v-col>
          <v-col
            cols="12"
            class="pa-0"
          >
            <v-select
              v-model="localData.client_id"
              :items="clientsName"
              label="Вкладка для клиента"
              item-value="id"
              item-text="value"
              class="pa-2"
              hide-details
            ></v-select>
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-actions class="justify-end">
        <v-btn
          color="primary"
          dark
          x-small
          @click="handleSave"
          class="mr-1"
        >
          Ок
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-menu>
</template>

<script>

export default {
  name: 'TabsDetail',
  props: {
    data: Object
  },
  data () {
    return {
      isOpen: false,
      localData: this.data
    }
  },
  computed: {
    clientsName () {
      return this.$store.getters['serviceData/optionsList']('users.clients')
    }
  },
  methods: {
    handleSave () {
      this.$emit('change', this.localData)
      this.isOpen = false
    }
  },
  mounted () {
    let profile = this.$store.getters['auth/getProfile']
    if (profile && (profile.role === 'agent' || profile.role === 'admin')) {
      this.localData.agent_id = profile.id
    }
  }
}
</script>

<style scoped>
  .tabButton {
    color: #000;
    border-radius: 3px;
  }
  .tabButton:hover {
    background: #ccc !important;
  }
</style>
